import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useLocation} from "react-router-dom";

// material-ui
import {styled, useTheme} from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import navigation from "menu-items";
import {drawerWidth} from "store/constant";
import {SET_MENU} from "store/actions";

// assets
import {IconChevronRight} from "@tabler/icons";
import {width} from "@mui/system";

// styles
const Main = styled("main", {shouldForwardProp: (prop) => prop !== "open"})(
  ({theme, open}) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: -(drawerWidth - 5),
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "0px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "0px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "0px",
        marginRight: "0px",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  const location = useLocation();
  const showAppBar = !["/hlog/dashboard", "/hlog/pdf"].includes(location.pathname);

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const userData = useSelector((state) => state.customization.userData);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({type: SET_MENU, opened: !leftDrawerOpened});
  };

  useEffect(() => {
    dispatch({type: SET_MENU, opened: !matchDownMd});
    // eslint-disable-next-line
  }, [matchDownMd]);

  return (
    <Box sx={{display: "flex"}}>
      <CssBaseline />
      {/* header */}
      {showAppBar && <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>

        <Box m={0} p={0} sx={{color: 'white', paddingRight: 4, paddingLeft: 4}}>
          <Typography variant="h3" fontWeight="bold" sx={{color: 'inherit'}}>
            {userData.firstName}
          </Typography>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Info value={userData.gender === 'M' ? "Male" : "Female"} />
            <Divider />
            <Info value={`${userData.age} yrs`} />
            <Divider />
            <Info value={userData.bloodGroup} />
          </div>
        </Box>
      </AppBar>}

      {/* drawer */}
      {/* <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      /> */}

      {/* main content */}
      {showAppBar ? <Main open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs
          separator={IconChevronRight}
          navigation={navigation}
          icon
          title
          rightAlign
        />
        <Outlet />
      </Main> : <Outlet />}
    </Box>
  );
};

const Info = ({value}) => (
  <Typography variant="subtitle1" sx={{color: 'white'}}>{value}</Typography>
);
const Divider = () => (
  <span style={{border: '0.25px solid #33CCCC', height: 8, marginLeft: 4, marginRight: 4}}></span>
);

export default MainLayout;
