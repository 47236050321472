import 'assets/scss/_themes-vars.module.scss';

const CloseButton = ({onClick}) => {
    return(
        <div className='Close-Button' onClick={onClick} style={{color: '#33cccc', cursor: 'pointer'}} >
            <svg width="42" height="42" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.744 10L18 15.385L12.256 10L10 12.115L15.744 17.5L10 22.885L12.256 25L18 19.615L23.744 25L26 22.885L20.256 17.5L26 12.115L23.744 10Z" fill="#49C1C3"/>
            </svg>
        </div>
    );
};
export default CloseButton;