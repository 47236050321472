import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {Typography, IconButton} from '@mui/material';
import { margin } from '@mui/system';
 
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
 
const PDFViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {state} = location;
  const {url, name} = state || {};
 
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);
  const viewerRef = useRef(null);
 
  console.log(url);
 
 
  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
  }
 
  const handleScroll = () => {
    if (viewerRef.current) {
      const {scrollTop, clientHeight} = viewerRef.current;
      const pageHeight = viewerRef.current.scrollHeight / numPages;
      const newCurrentPage = Math.ceil((scrollTop + clientHeight / 2) / pageHeight);
      setCurrentPage(newCurrentPage);
    }
  };
 
  const handleResize = () => {
    setWidth(window.innerWidth);
  };
 
  useEffect(() => {
    const viewer = viewerRef.current;
 
    window.addEventListener('resize', handleResize);
    viewer?.addEventListener('scroll', handleScroll);
    return () => {
      viewer?.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [numPages]);
 
  const styles = {
    container: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#FFFFFF',
      backgroundColor: '#2c3e50',
    },
    title: {
      flex: 1,
      textAlign: 'center',
      color: 'white',
      fontWeight: 'bold',
      marginLeft : 30
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      top: 0,
      width: '100%',
      padding: '15px',
      zIndex: 1,
      backgroundColor: '#2c3e50',
    },
    footer: {
      padding: '10px',
      textAlign: 'center',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      zIndex: 1,
    },
    iconButton: {
      color: 'white',
      marginRight: '10px',
      position: 'absolute',
      left: '15px', 
    },
  };
 
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <IconButton style={styles.iconButton} onClick={() => navigate(-1)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25 11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H5.25C5.05109 12.75 4.86032 12.671 4.71967 12.5303C4.57902 12.3897 4.5 12.1989 4.5 12C4.5 11.8011 4.57902 11.6103 4.71967 11.4697C4.86032 11.329 5.05109 11.25 5.25 11.25Z" fill="white"/>
            <path d="M5.56062 12L11.7811 18.219C11.9219 18.3598 12.0011 18.5508 12.0011 18.75C12.0011 18.9491 11.9219 19.1401 11.7811 19.281C11.6403 19.4218 11.4493 19.5009 11.2501 19.5009C11.0509 19.5009 10.8599 19.4218 10.7191 19.281L3.96912 12.531C3.89927 12.4613 3.84386 12.3785 3.80605 12.2874C3.76824 12.1963 3.74878 12.0986 3.74878 12C3.74878 11.9013 3.76824 11.8036 3.80605 11.7125C3.84386 11.6214 3.89927 11.5386 3.96912 11.469L10.7191 4.71897C10.8599 4.57814 11.0509 4.49902 11.2501 4.49902C11.4493 4.49902 11.6403 4.57814 11.7811 4.71897C11.9219 4.8598 12.0011 5.05081 12.0011 5.24997C12.0011 5.44913 11.9219 5.64014 11.7811 5.78097L5.56062 12Z" fill="white"/>
          </svg>
        </IconButton>
        <Typography variant="h3" style={styles.title}>{name}</Typography>
      </div>
      <div
        ref={viewerRef}
        style={{flex: 1, overflowY: 'scroll', width: '100%', marginTop: '70px', marginBottom: '40px'}}
      >
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width} />
          ))}
        </Document>
      </div>
      <div style={styles.footer}>
        Page {currentPage} of {numPages}
      </div>
    </div>
  );
};
 
export default PDFViewer;
