import React from 'react';
import { Button } from '@mui/material';

const BottomButton = ({ onClick , buttonName}) => {
    return (
        <Button
            onClick={onClick}
            variant="contained"
            sx={{
                width: '100%',
                height: {xs:52,md:46},
                borderRadius: 1,
                backgroundColor: '#49C1C3',
                fontSize: 22,
                fontWeight: 500,
                font: 'Montserrat',
                '&:hover': {
                    backgroundColor: '#3AAFA9',
                },
            }}
        >
            {buttonName}
        </Button>
    );
};

export default BottomButton;
