// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import Logo from "ui-component/Logo";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase disableRipple>
    <Logo />
  </ButtonBase>
);

export default LogoSection;
