import {useSearchParams} from "react-router-dom";

const useExtractRawQueryParams = () => {
  const [searchParams] = useSearchParams();
  const encryptedValue = searchParams.get('xpwf') || '';
  const key = searchParams.get('lpsq') || '';
  
  console.log(`encryptedValue ${encryptedValue}`);
  

  return { encryptedValue, key };
};

export default useExtractRawQueryParams;
