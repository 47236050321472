import React from 'react';

const DischargeSummarySvg = () => {
  return (
    <div>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="4" fill="#49C1C3"/>
<path d="M19.5 25.5H25.5V27H19.5V25.5ZM19.5 21.75H28.5V23.25H19.5V21.75ZM19.5 29.25H23.25V30.75H19.5V29.25Z" fill="#41586E"/>
<path d="M30.75 15.75H28.5V15C28.5 14.6022 28.342 14.2206 28.0607 13.9393C27.7794 13.658 27.3978 13.5 27 13.5H21C20.6022 13.5 20.2206 13.658 19.9393 13.9393C19.658 14.2206 19.5 14.6022 19.5 15V15.75H17.25C16.8522 15.75 16.4706 15.908 16.1893 16.1893C15.908 16.4706 15.75 16.8522 15.75 17.25V33C15.75 33.3978 15.908 33.7794 16.1893 34.0607C16.4706 34.342 16.8522 34.5 17.25 34.5H30.75C31.1478 34.5 31.5294 34.342 31.8107 34.0607C32.092 33.7794 32.25 33.3978 32.25 33V17.25C32.25 16.8522 32.092 16.4706 31.8107 16.1893C31.5294 15.908 31.1478 15.75 30.75 15.75ZM21 15H27V18H21V15ZM30.75 33H17.25V17.25H19.5V19.5H28.5V17.25H30.75V33Z" fill="#41586E"/>
</svg>
    </div>
  );
};

export default DischargeSummarySvg;
