import Drawer from '@mui/material/Drawer';
import { useMediaQuery } from '@mui/system';
import 'assets/scss/_themes-vars.module.scss';

const CustomDrawer = ({open,onClose,children}) => {
    const isDesktop = useMediaQuery('(min-width:1024px)');

    return(
        <Drawer
            anchor='bottom'
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDrawer-paper': {
                    borderTopLeftRadius: isDesktop ? '0' : '22px',
                    borderTopRightRadius: isDesktop ? '0' : '22px',
                    overflow: 'hidden',
                    height: isDesktop ? '100%' : 'auto', 
                    backgroundColor: isDesktop ? '#2C3E50' : '#41586E',
                },
            }}
        >
            {children}
        </Drawer>
    );
};
export default CustomDrawer;

    