import React from 'react';

const PrescriptionsSvg = () => {
  return (
    <div>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="4" fill="#49C1C3"/>
<g clip-path="url(#clip0_1118_5420)">
<path d="M18 31V15H22.5C23.6935 15 24.8381 15.4741 25.682 16.318C26.5259 17.1619 27 18.3065 27 19.5C27 20.6935 26.5259 21.8381 25.682 22.682C24.8381 23.5259 23.6935 24 22.5 24H18" stroke="#41586E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31 33L22 24" stroke="#41586E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25 33L31 27" stroke="#41586E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1118_5420">
<rect width="24" height="24" fill="white" transform="translate(12 12)"/>
</clipPath>
</defs>
</svg>

    </div>
  );
};

export default PrescriptionsSvg;