// assets
import {
  IconBrandChrome,
  IconHelp,
  IconCalendarEvent,
  IconUser,
  IconFileText,
} from "@tabler/icons";

// constant
const icons = {
  IconBrandChrome,
  IconHelp,
  IconCalendarEvent,
  IconUser,
  IconFileText,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: "sample-docs-roadmap",
  type: "group",
  children: [
    // {
    //   id: "create-appointment-page",
    //   title: "",
    //   type: "item",
    //   url: "/create-appointment",
    //   icon: icons.IconCalendarEvent,
    //   breadcrumbs: false,
    // },
    // {
    //   id: "tableau-page",
    //   title: "",
    //   type: "item",
    //   url: "/tableau-page",
    //   icon: icons.IconUser,
    //   breadcrumbs: false,
    // },
    // {
    //   id: "doc-view",
    //   title: "",
    //   type: "item",
    //   url: "/doc-view",
    //   icon: icons.IconFileText,
    //   breadcrumbs: false,
    // },
    // {
    //   id: "documentation",
    //   title: "Documentation",
    //   type: "item",
    //   url: "",
    //   icon: icons.IconHelp,
    //   external: true,
    //   target: true,
    // },
  ],
};

export default other;
