import React from 'react';

const RightArrowSvg = ({disabled = false}) => {
  return (
    <div>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.965 10.0652C13.826 10.2039 13.7157 10.3688 13.6404 10.5502C13.5651 10.7317 13.5264 10.9262 13.5264 11.1227C13.5264 11.3191 13.5651 11.5136 13.6404 11.6951C13.7157 11.8766 13.826 12.0414 13.965 12.1802L19.785 18.0002L13.965 23.8202C13.6846 24.1006 13.527 24.481 13.527 24.8777C13.527 25.2743 13.6846 25.6547 13.965 25.9352C14.2455 26.2156 14.6259 26.3732 15.0225 26.3732C15.4192 26.3732 15.7996 26.2156 16.08 25.9352L22.965 19.0502C23.1041 18.9114 23.2144 18.7466 23.2897 18.5651C23.365 18.3836 23.4037 18.1891 23.4037 17.9927C23.4037 17.7962 23.365 17.6017 23.2897 17.4202C23.2144 17.2388 23.1041 17.0739 22.965 16.9352L16.08 10.0502C15.51 9.48015 14.55 9.48016 13.965 10.0652Z" fill={disabled ? '#aaaaaa' : "#5FBEAC"} />
      </svg>
    </div>
  );
};

export default RightArrowSvg;


