import MedugoSVG from "assets/images/MedugoSVG";


const Logo = () => {
  return (
    <MedugoSVG  background="#2c3e51" width={200} height={70}/>
  );
};

export default Logo;

