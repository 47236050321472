const fetchApi = async ({url, method = "GET", options = {}}) => {
  try {
    const { headers, body } = options;
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'x-apikey': 'Ymge2Q1tLkB3dUwqdS6vtTY41I35tKQh5lgWKa2pHCHX0e7q9j6XIyzMgfYubvPL',
        ...headers
      },
      body: body ? JSON.stringify(body) : undefined, 
    });

    if (!response.ok) {
      console.log(`Fetch Person Details: ${response.status} - ${response.statusText}`);
      const errorBody = await response.json();
      throw new Error(JSON.stringify(errorBody));
    }
    return await response.json();
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : e;

    console.log(`Error fetching person details: ${errorMessage}`);
    throw new Error(errorMessage);
  }
};

const fetchPersonDetails = async (encryptedValue, key) =>
  fetchApi({
    url: `${process.env.REACT_APP_BASEURL}/get/persondetails/${encryptedValue}/${key}`
  });

const fetchTableauToken = async (encryptedValue, key) =>
  fetchApi({
    url: `${process.env.REACT_APP_BASEURL}/v2/TableauToken?encryptedValue=${encryptedValue}&uid=${key}`
  });

const fetchHealtHistory = async (encryptedValue, key) =>
  fetchApi({
    url: `${process.env.REACT_APP_BASEURL}/v2/my/treatment/doctor?encryptedValue=${encryptedValue}&uid=${key}`
  });

const createAppointment = async (encryptedValue, key, payload) =>
  fetchApi({
    method: 'POST',
    url: `${process.env.REACT_APP_BASEURL}/v2/createappointment`,
    options: {
      body: {
        ...payload,
        encryptedValue,
        uid: key
      }
    }
  });

  const webLogin = async (payload) =>
    fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/v1/web/login`,
      options: {
        body: {
          ...payload,
        }
      }
    });

    const loginOtpVerify = async (payload) =>
      fetchApi({
        method: 'POST',
        url: `${process.env.REACT_APP_BASEURL}/v1/weblogin/verifyotp`,
        options: {
          body: {
            ...payload,
          }
        }
      });

      const webLogout = async (payload) =>
        fetchApi({
          method: 'POST',
          url: `${process.env.REACT_APP_BASEURL}/v1/web/logout`,
          options: {
            body: [
              ...payload,
            ]
          }
        });

        const requestOtp = async (payload) =>
          fetchApi({
            method: 'POST',
            url: `${process.env.REACT_APP_BASEURL}/requestotp`,
            options: {
              body: {
                ...payload,
              }
            }
          });

export {
  fetchPersonDetails,
  fetchTableauToken,
  fetchHealtHistory,
  createAppointment,
  webLogin,
  loginOtpVerify,
  webLogout,
  requestOtp,
};