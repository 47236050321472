import React from 'react';

const InvalidLinkMessage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Link is Invalid or Expired!</h1>
      <p style={styles.message}>
        The link you are trying to access is no longer valid. Please check the link or request a new one.
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: '#343a40',
    textAlign: 'center',
    padding: '20px',
    boxSizing: 'border-box', // Ensures padding is included in the width calculation
  },
  heading: {
    fontSize: '2em',
    marginBottom: '20px',
    color: '#eee',
    lineHeight: '1em'
  },
  message: {
    fontSize: '1.2em',
    maxWidth: '400px',
    margin: '0 20px',
    color: '#eee'
  },
};

export default InvalidLinkMessage;
