import dashboard from "./dashboard";
import pages from "./pages";
import other from "./other";

// ==============================|| MENU ITEMS ||============================== //

// const menuItems = {
//   items: [dashboard, pages, other],
// };

const menuItems = {
  items: [dashboard, other],
};

export default menuItems;
