import React from 'react';

const BloodReportSvg = () => {
  return (
    <div>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="4" fill="#49C1C3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21 32H23.5V34H24.5V32H27V31H29C29.5304 31 30.0391 30.7893 30.4142 30.4142C30.7893 30.0391 31 29.5304 31 29V17.057C31 16.5266 30.7893 16.0179 30.4142 15.6428C30.0391 15.2677 29.5304 15.057 29 15.057H26L25.434 14.475C25.2476 14.2833 25.0247 14.1309 24.7784 14.0268C24.5321 13.9228 24.2674 13.8691 24 13.8691C23.7326 13.8691 23.4679 13.9228 23.2216 14.0268C22.9753 14.1309 22.7524 14.2833 22.566 14.475L22 15.057H19C18.4696 15.057 17.9609 15.2677 17.5858 15.6428C17.2107 16.0179 17 16.5266 17 17.057V29C17 29.5304 17.2107 30.0391 17.5858 30.4142C17.9609 30.7893 18.4696 31 19 31H21V32ZM25.283 15.754C25.3762 15.8499 25.4877 15.9261 25.6108 15.9781C25.734 16.0302 25.8663 16.057 26 16.057H29C29.2652 16.057 29.5196 16.1624 29.7071 16.3499C29.8946 16.5374 30 16.7918 30 17.057V25.77C29.8533 25.655 29.6988 25.5504 29.5375 25.457C28.625 24.926 27.3055 24.65 25.746 25.57C24.462 26.3275 23.2545 26.5325 21.941 26.56C21.356 26.5725 20.757 26.55 20.1155 26.5255L19.8505 26.5155C19.2341 26.4888 18.617 26.4786 18 26.485V17.0575C18 16.7923 18.1054 16.5379 18.2929 16.3504C18.4804 16.1629 18.7348 16.0575 19 16.0575H22C22.1337 16.0575 22.266 16.0307 22.3892 15.9786C22.5123 15.9266 22.6238 15.8504 22.717 15.7545L23.283 15.1725C23.3762 15.0766 23.4877 15.0004 23.6108 14.9484C23.734 14.8964 23.8663 14.8696 24 14.8696C24.1337 14.8696 24.266 14.8964 24.3892 14.9484C24.5123 15.0004 24.6238 15.0766 24.717 15.1725L25.283 15.754ZM18 27.4855V29C18 29.2652 18.1054 29.5196 18.2929 29.7071C18.4804 29.8946 18.7348 30 19 30H29C29.2652 30 29.5196 29.8946 29.7071 29.7071C29.8946 29.5196 30 29.2652 30 29V27.165C29.7328 26.8268 29.4055 26.5408 29.0345 26.3215C28.3875 25.9445 27.4575 25.721 26.2545 26.4305C24.7755 27.303 23.383 27.5295 21.962 27.5595C21.345 27.5725 20.715 27.5485 20.077 27.5245L19.8135 27.5145C19.2094 27.4882 18.6047 27.4785 18 27.4855Z" fill="#2C3E50"/>
</svg>
    </div>
  );
};

export default BloodReportSvg;